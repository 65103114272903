
import React, { useState, useEffect } from 'react';
import Footer from './footer';
import Header from './Header';

const Termandcondition = () => {
  const [htmlContent, setHtmlContent] = useState('');  // State to store HTML content
  const [loading, setLoading] = useState(true);  // State for loading indicator
  const [error, setError] = useState(null);  // State for error handling

  useEffect(() => {
    // Fetch data from API
    const fetchHtmlContent = async () => {
      try {
        const response = await fetch('https://api.nanahelps.com/get_terms'); // Replace with your API endpoint
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        setHtmlContent(jsonData.terms_and_condtion.terms_and_condition);  // Store the HTML content in the state
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);  // Turn off the loading indicator
      }
    };

    fetchHtmlContent();
  }, []);  // Empty array to run only once when the component mounts

  // Handle loading and error states
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <Header />
      <h1>Term And Conditions are below!</h1>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />  {/* Render HTML */}
      <Footer/>
    </div>
  );
};

export default Termandcondition;
 
