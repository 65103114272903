import React, { useState } from "react";
import Header from "./Header";
import Footer from "./footer";

const Datadeletion = () => {
  const [query, setQuery] = useState(""); // State for the message
  const [email, setEmail] = useState(""); // State for the message
  const [phone, setPhone] = useState(); // State for the message
  const [responseMessage, setResponseMessage] = useState(""); // State to store the API response
  const [error, setError] = useState(null); // State for error handling
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page reload
    setLoading(true);
    setError(null);
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false); // Hide the message after 3 seconds
    }, 3000);

    const formData = new FormData();
    formData.append("phone", phone);
    formData.append("query", query);
    formData.append("email", email);

    try {
      const response = await fetch(
        "https://api.nanahelps.com/user/send_help_message",
        {
          // Replace with your API endpoint
          method: "POST",
          // headers: {
          //   'Content-Type': 'application/json',
          // },
          body: formData, // Send the message as a JSON object
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send message");
      }

      const jsonResponse = await response.json(); // Parse the response
      setResponseMessage(jsonResponse.message); // Set the response message (Assuming the API sends a status message)
    } catch (error) {
      setError(error.message); // Set error message if there's an issue
    } finally {
      setLoading(false); // Turn off loading indicator
    }
  };

  return (
    <div>
      
      <Header />
     
      <div className="container mt-5">
        <div className="row">
          <div className="col">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3194.8340142159377!2d86.27209712735653!3d23.799946328890282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sakashkinaree%20colliery!5e0!3m2!1sen!2sin!4v1728288701807!5m2!1sen!2sin"
              width={"100%"}
              height={400}
              frameBorder={0}
              style={{ border: 0 }}
              allowFullScreen="true"
              aria-hidden="false"
              tabIndex={0}
            ></iframe>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row d-flex data-deletion">
          <div className="col">
            {showSuccess && (
              <div
                className="alert alert-success"
                style={{ width: "100%" }}
                role="alert"
              >
                {responseMessage}
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <h4>Data deletion request!</h4>

              <div className="mb-3">
                <input
                  type="number"
                  className="form-control"
                  name="phone"
                  id="formGroupExampleInput"
                  placeholder="enter your phone"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="formGroupExampleInput2"
                  placeholder="Enter your Email id"
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                />
              </div>
              <div className="mb-3">
                <textarea
                  type="text"
                  name="query"
                  onChange={(e) => setQuery(e.target.value)}
                  rows={4}
                  className="form-control"
                  id="formGroupExampleInput2"
                  placeholder="Enter your Message"
                />
              </div>
              <input
                type="submit"
                className="btn btn-primary pointer"
                value={"Submit"}
              />
            </form>
          </div>

          <div
            className="col contact-details mb-3 "
            style={{ backgroundColor: "#F3F5F7" }}
          >
            <h4>Contact Details</h4>
            <div className="location d-flex mt-4 jus">
              <div>
                <i class="fa-solid fa-location-dot"></i>
              </div>
              <div className="ms-4">
                <p>Katras ,Dhanbad Jharkhand,828114</p>
              </div>
            </div>
            <div className="location d-flex mt-2">
              <div>
                <i class="fa-solid fa-phone-volume"></i>
              </div>
              <div className="ms-4">
                <p>9117151234</p>
              </div>
            </div>
            <div className="location d-flex mt-2">
              <div>
                <i class="fa-solid fa-envelope"></i>
              </div>
              <div className="ms-4">
                <p>team.nanahelps@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Footer/>
       
    </div>
  );
};

export default Datadeletion;
