 
import React from "react"; 
import Header from "./Header";
import Footer from "./footer";

const Home = () => {
  const playStoreUrl =
    "https://play.google.com/store/apps/details?id=com.nanaudyog";

  const handleClick = () => {
    window.open(playStoreUrl, "_blank");
  };
  return (
    <div>
      {/* <div className="container">
        <div className="row">
          <div className="col"> */}
       <Header />  
            
          {/* </div> */}
           {/*  */}
        {/* </div> */}
      {/* // </div> */}
      <div className="container">
      <div className="row">
        <div className="col">
        <div className="banner-section">
        <div className="banner-section-contents">
          <div className="banner-image-left-side">
            <img src="/img/theme/banner-left.webp"  className="img-fluid" alt="" />
          </div>
          <div className="banner-between-contents">
            <div className="card">
              <div className="card-body">
                <h1 className="card-body-text">
                  Find local jobs and Services with better salary!
                </h1>
                <br />
                <div className="hr-text">
                  <p className="text-danger">Download NanaHelps app on playstore and Ios</p>
                </div>
                <div className="btn-text">
                  <button className="btn btn-primary">Get a Job Now</button>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-image-right-side">
            <img src="/img/theme/banner-right.webp" className="img-fluid" alt="" />
          </div>
        </div>
      </div>
        </div>
        
      </div>
</div>   
{/* slider */}
  <div className="container">
  <div className="row">
  <div
  id="carouselExampleSlidesOnly"
  className="carousel slide"
  data-bs-ride="carousel"
>
  <div className="carousel-inner ">
    <div className="carousel-item active ">
      <img src="nanabanner/nana-service.jpg"   className=" d-block w-100  img-fluid mx-auto object-fit-fill " alt="..." />
    </div>
    <div className="carousel-item">
      <img src="nanabanner/nana-service.jpg"   className=" d-block w-100  img-fluid mx-auto object-fit-fill " alt="..." />
    </div>
         
    
  </div>
</div>

  </div>
</div>  
  
   <div className="container role">
  <div className="row">
    <div className="col">
    <h1 className="d-flex justify-content-center mb-5 m-3 fw-bolder">
          Explore jobs in 400+ cities
        </h1>
      <div className="explore-more-cityss "> 
          <div className="Delhi-citys col-md-2">
          <figure>
            <img
              className="img-fluid"
              src="/img/theme/licensed-image.jpeg"
               
              height="165px"
              alt=""
            />
              <figcaption>Dhanbad</figcaption>
          </figure>
            <p>Dhanbad</p>
            <p>8k +Jobs</p> 
          </div>
         <div className="Delhi-citys col-md-2">
          <figure>
        
            <img
             className="img-fluid"
              src="/img/theme/delhi.jpg"
              height="160px"
              alt=""
            />
                  <figcaption>Delhi</figcaption>
          </figure>
            <p>Delhi</p>
            <p>50k +Jobs</p>
          </div>
          <div className="Delhi-citys col-md-2">
                
          <figure>
            <img
              src="/img/theme/kolkata.jpg"
              className="img-fluid"
              height="160px"
              alt=""
            />
                 <figcaption>Kolkata</figcaption>
          </figure>
            <p>Kolkata</p>
            <p>63k +Jobs</p>
          </div>
          <div className="Delhi-citys col-md-2">
                
          <figure>
            <img
            className="img-fluid"
              src="/img/theme/mumbai.jpg"
              
              height="160px"
              alt=""
            />
                 <figcaption>Mumbai</figcaption>
                
          </figure>
            <p>Mumbai</p>
            <p>90k +Jobs</p>
          </div>
          <div className="Delhi-citys col-md-2">
                
          <figure>
            <img
            className="img-fluid"
              src="/img/theme/bangalore.jpg"
             
              height="160px"
              alt=""
            />
                 <figcaption>Bangalore</figcaption>
                
          </figure>
            <p>Bangalore</p>
            <p>40k +Jobs</p>
          </div> 
        </div>  
    </div>
    
  </div>
</div>  
  <div className="container mt-2">
  <div className="row">
  <h1 class="text-center">
        What kind of a role do you want?
      </h1>
    <div className="col role-side m-2">
  
    <div className="role-cat">
          
    <figure>
              <img
                src="/img/theme/delivery.jpg"
                width="250px"
                height="160px"
                alt=""
              />
                  
          </figure>
              <p className="fw-bold">Delivery</p>
              <p  >
                View 52000 +Vacancies
                <span>
                  <i
                    className="fa-solid fa-chevron-right"
                    style={{ fontSize: 12 }}
                  />
                </span>
              </p>
            </div>
            <div className="role-cat">
                  
          <figure>
              <img
                src="/img/theme//driver.jpg"
                width="250px"
                height="160px"
                alt=""
              />
                  
          </figure>
              <p className="fw-bold">Driver</p>
              <p  >
                View 3200 +Vacancies
                <span>
                  <i
                    className="fa-solid fa-chevron-right"
                    style={{ fontSize: 12 }}
                  />
                </span>
              </p>
            </div>
            <div className="role-cat">
                  
          <figure>
              <img
                src="/img/theme/warehous.jpg"
                width="250px"
                height="160px"
                alt=""
              />
                  
          </figure>
              <p className="fw-bold">Warehouse</p>
              <p >
                View 78000 +Vacancies
                <span>
                  <i
                    className="fa-solid fa-chevron-right"
                    style={{ fontSize: 12 }}
                  />
                </span>
              </p>
            </div>
            <div className="role-cat">
                  
          <figure>
              <img
                src="/img/theme/manufacturint.jpg"
                width="250px"
                height="160px"
                alt=""
              />
                  
          </figure>
              <p className="fw-bold">Manufacturing</p>
              <p  >
                View 74000 +Vacancies
                <span>
                  <i
                    className="fa-solid fa-chevron-right"
                    style={{ fontSize: 12 }}
                  />
                </span>
              </p>
            </div>
            <div className="role-cat">
                  
          <figure>
              <img
                src="/img/theme/peon.jpg"
                width="250px"
                height="160px"
                alt=""
              />
                  
          </figure>
              <p className="fw-bold">Peon</p>
              <p  >
                View 3000 +Vacancies
                <span>
                  <i
                    className="fa-solid fa-chevron-right"
                    style={{ fontSize: 12 }}
                  />
                </span>
              </p>
            </div>
          </div>
        </div>
    </div>   
         
       <Footer/> 
    </div>
  );
};

export default Home;
