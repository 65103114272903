// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About-us";
import ContactUs from "./components/Contactus";
import Privacypolicy from "./components/Privacy-policy";
import TermAndCondition from "./components/Term-and-condition";
import Datadeletion from "./components/Data-deletioon";
import Refundandcancellationpolicy from "./components/Refund-and-cancellation-policy";
import NotFound from "./components/NotFound";
import Helpus from "./components/Help-us";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Privacypolicy" element={<Privacypolicy />} />
        <Route path="/TermAndCondition" element={<TermAndCondition />} />
        <Route path="/Refundandcancellationpolicy" element={<Refundandcancellationpolicy />} />
        <Route path="/Datadeletion" element={<Datadeletion />} />
        <Route path="/Helpus" element={<Helpus />} />
        <Route element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
