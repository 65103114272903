import React from "react";
import { Link } from "react-router-dom";
import fc from "../../src/fcbk.png";
import ins from "../../src/ins.png";
import yt from "../../src/yt.png";
import tw from "../../src/twit.jpg";
 

const Footer = () => {
  const playStoreUrl =
    "https://play.google.com/store/apps/details?id=com.nanaudyog";

  const handleClick = () => {
    window.open(playStoreUrl, "_blank");
  };
  return (
    <div>
      <footer>
        <div className="footer-section">
          <div className="footer-logo">
            <div className="brand-logo">
              <Link to={"/"}>
              <img
                src="img/brand/nanahelps.jpg"
                width="70px"
                height="70px"
                alt=""
                />
                </Link>
            </div>
            <div className="googlePlay ">
              <img
                src="img/theme/google-play-badge.png"
                style={{ marginTop: 35, cursor:"pointer"}}
               
                width="180px"
                height="60px"
                alt=""
                onClick={handleClick}
              />
            </div>
          </div>
          <div className="footer-company">
            <div className="footer-compnay-text">
              <p className="footer-company-border">Compnay</p>
            </div>
            <div className="footer-about-us-text ft-text">
              <Link to={"/About"}>
                <p>About Us</p>
              </Link>
            </div>
            <div className="footer-about-us-text ft-text">
              <p>Cookies Policy</p>
            </div>
            <div className="footer-about-us-text ft-text">
              <p>Post a Job</p>
            </div>
            <div className="footer-about-us-text ft-text">
              <p>Resumie builder</p>
            </div>
            <div className="footer-about-us-text ft-text">
              <p>Nana Help blog</p>
            </div>
            <div className="footer-about-us-text ft-text">
              <p>Sitemap</p>
            </div>
          </div>
          <div className="footer-support">
            <div className="footer-compnay-text ft-text ">
              <p className="footer-company-border">Support</p>
            </div>
            <div className="footer-about-us-text ft-text">
              <Link to={"/Datadeletion"}>
                <p>Data deletion request</p>
              </Link>
            </div>
            <div className="footer-about-us-text ft-text">
              <Link to={"/Privacypolicy"}>
                <p>Privacy Policy</p>
              </Link>
            </div>
            <div className="footer-about-us-text ft-text">
              <Link to={"/TermAndCondition"}>
                <p>Term &amp; Conditions</p>
              </Link>
            </div>
            <div className="footer-about-us-text ft-text">
              <Link to={"/Refundandcancellationpolicy"}>
                <p>Refund and cancelation policy</p>
              </Link>
            </div>
            <div className="footer-about-us-text ft-text">
              <Link to={"/Helpus"}>

              <p>Help us</p>
              </Link>
            </div>
            {/* <div className="footer-about-us-text">
              <Link to={"/Datadeletion"}>
                <p>Data Deletion Request</p>
              </Link>
            </div> */}
          </div>
          <div className="footer-social">
            <div className="footer-compnay-text">
              <p className="footer-company-border">
                Social <span className="ms-2">Links</span>{" "}
              </p>
            </div>
            <div className="footer-about-us-text">
              <div className="social-link">
                <a href="https://www.facebook.com/nanaUDYOG/">
                  <img
                    className="socialLogo"
                    src={fc}
                    alt="Description of image"
                  />
                </a>

                <a href="https://www.instagram.com/nanahelps/?igsh=cmNsdmQ4dGY3MmR2">
                  <img
                    className="socialLogo"
                    src={ins}
                    alt="Description of image"
                  />
                </a>
                <a href="https://www.youtube.com/">
                  <img
                    className="socialLogo"
                    src={yt}
                    alt="Description of image"
                  />
                </a>
                <a href="https://x.com/Nana_Helps?t=-v-npA8AnUYiaQSwNUdfdg&s=09">
                  <img
                    className="socialLogo"
                    src={tw}
                    alt="Description of image"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid copy-wright text-danger">
          <div className="row">
            <div className="col">
              <p>All rights reserved © 2024 nana Helps </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Footer;
